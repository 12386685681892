<template>
  <div class="funcs">
    <NuxtLink v-if="widgetConfig?.quickAccessList" :to="bookmarksLink">
      <ButtonCounter
        variant="secondary"
        :content="{
          icon: 'ion:heart-outline',
          text: '',
          count: globalStore.state.bookmarksCount,
        }"
      />
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
const widgetConfig = await useWidgetConfig();
const localePath = useLocalePath();
const globalStore = useGlobalStore();

const bookmarksLink = localePath(prefixLinkPath('/bookmarks', widgetConfig));
</script>

<style src="./BottomFuncs.scss" scoped lang="scss"></style>
